import pdpService from '@/services/PdpService';

const exportReviewDate = async (context, processId) => {
  return await pdpService.exportReviewDate(processId);
};

const setNextReview = async (context, reviewDate) => {
  return await pdpService.setReview(reviewDate);
};

const getAsRecipient = async () => {
  return await pdpService.getAsRecipient();
};

const getAsSupervisor = async (context, userId) => {
  return await pdpService.getAsSupervisor(userId);
};

const listAll = async () => {
  return await pdpService.listAll();
};

const listFromHierarchy = async () => {
  return await pdpService.listFromHierarchy();
};

const uploadDocument = async (context, file) => {
  return await pdpService.uploadDocument(file);
};

export default {
  exportReviewDate,
  setNextReview,
  getAsRecipient,
  getAsSupervisor,
  uploadDocument,
  listAll,
  listFromHierarchy
};
