const getProcesses = (state) => {
  return state.processes;
};

const getProcessesUpdated = (state) => {
  return state.processesUpdated;
};

const getDatesUpdated = (state) => {
  return state.datesUpdated;
};

const getTasksUpdated = (state) => {
  return state.tasksUpdated;
};

const getStatuses = (state) => {
  return state.statuses;
};

export default {
  getProcesses,
  getProcessesUpdated,
  getDatesUpdated,
  getTasksUpdated,
  getStatuses
};
