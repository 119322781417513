import api from '@/helpers/api.js';

export default {
  async saveWorkflow(workflowData) {
    return await api.post(`/Workflows`, workflowData);
  },

  async deleteWorkflow(workflowId) {
    return await api.delete(`/Workflows/${workflowId}`);
  },

  async getWorkflows() {
    return await api.get('/Workflows');
  }
};
