import dashboardService from '@/services/DashboardService';

const buildDashboardData = async () => {
  await dashboardService.buildData();
};

const updateProcesses = ({ commit }) => {
  commit('setProcessesUpdated', true);
};

const getProcesses = async ({ state, commit }, processType) => {
  if (state.processesUpdated) {
    commit('setProcessesUpdated', false);
    commit('setProcesses', await dashboardService.getProcesses());
  }
  var processes = state.processes;
  if (processType) {
    processes = processes.filter((x) => x.processType === processType);
  }
  return processes;
};

const updateDates = ({ commit }) => {
  commit('setDatesUpdated', true);
};

const getDates = async ({ state, commit }) => {
  if (state.datesUpdated) {
    commit('setDatesUpdated', false);
    commit('setDates', await dashboardService.getDates());
  }
  return state.dates;
};

const updateTasks = ({ commit }) => {
  commit('setTasksUpdated', true);
};

const getTasks = async ({ state, commit }) => {
  if (state.tasksUpdated) {
    commit('setTasksUpdated', false);
    const tasks = await dashboardService.getTasks();
    commit('setTasks', tasks);
  }
  return state.tasks;
};

const setTaskVisibility = async (context, data) => {
  await dashboardService.setTaskVisibility(data);
};

const getWidgets = async ({ state, commit }) => {
  if (!state.widgets || state.widgets.length === 0) {
    const widgets = await dashboardService.getWidgets();
    commit('setWidgets', widgets);
  }
  return state.widgets;
};

const saveWidgets = async (context, widgets) => {
  await dashboardService.saveWidgets(widgets);
};

export default {
  buildDashboardData,
  updateProcesses,
  getProcesses,
  updateDates,
  getDates,
  updateTasks,
  getTasks,
  setTaskVisibility,
  getWidgets,
  saveWidgets
};
