import api from '@/helpers/api.js';

export default {
  async getMyEmployees() {
    var data = await api.get('/Users/my-employees');
    return data.users;
  },

  async getDataOptions() {
    var data = await api.get('/users/options');
    return data;
  },

  async getUserDetails(shorthand) {
    return api.get(`/users/${shorthand}`);
  },

  async getUsers() {
    var data = await api.get('/Users');
    return data;
  },

  async saveUser(employee) {
    var data = await api.put('/Users', employee);
    return data;
  }
};
