import careerService from '@/services/CareerDevelopmentService';
import feedbackService from '@/services/FeedbacksService';
import talkroundService from '@/services/TalkroundService';

const addPromotionUser = async (context, promotionUserData) => {
  return careerService.addPromotionUser(promotionUserData);
};

const confirmPromotion = async (context, processId) => {
  careerService.setPromotionResult(processId, true);
};

const denyPromotion = async (context, processId) => {
  careerService.setPromotionResult(processId, false);
};

const getCareerData = async (context, feedbackId) => {
  return await careerService.getCareerData(feedbackId);
};

const getComparisonData = async (context, data) => {
  return await careerService.getComparisonData(data.feedbackId, data.processId);
};

const getPromotionUsers = async () => {
  return await careerService.getPromotionUsers();
};

const listDevelopmentPositions = async ({ state }) => {
  if (state.developmentPositions.length > 0) {
    return state.developmentPositions;
  }

  state.developmentPositions = await feedbackService.listDevelopmentPositions();
  return state.developmentPositions;
};

const listTalkrounds = async ({ state }) => {
  if (state.talkrounds.length > 0) {
    return state.talkrounds;
  }

  const filters = {
    processType: 4,
    excludeFinishedTalkrounds: true
  };

  let talkrounds = await talkroundService.listTalkrounds(filters);
  talkrounds = talkrounds.filter((x) => x.processType === 4);

  state.talkrounds = talkrounds;

  return talkrounds;
};

const setHardfactsState = async (context, data) => {
  careerService.setHardfactsState(data.processId, data.state);
};

export default {
  addPromotionUser,
  confirmPromotion,
  denyPromotion,
  getCareerData,
  getComparisonData,
  getPromotionUsers,
  listDevelopmentPositions,
  listTalkrounds,
  setHardfactsState
};
