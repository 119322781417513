import api from '@/helpers/api.js';

export default {
  async getEmails(keyword, pageIndex, pageSize, sort, desc) {
    var data = await api.get('/Emails', {
      keyword,
      pageIndex,
      pageSize,
      sort,
      desc
    });
    return data;
  },
  async getEmail(id) {
    var data = await api.get(`/Emails/${id}`);
    return data;
  }
};
