<template>
  <v-menu
    v-model="settingsMenu"
    bottom
    left
    offset-y
    :close-on-content-click="false"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on: onMenu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            text
            v-bind="attrs"
            v-on="{ ...onMenu, ...onTooltip }"
            height="100%"
          >
            <v-icon>mdi-cog</v-icon>
          </v-btn>
        </template>
        <span>{{ $t('settings') }}</span>
      </v-tooltip>
    </template>

    <v-list dense>
      <v-list-group v-model="languageMenu" prepend-icon="mdi-web" no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ $t('language') }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item @click="$emit('setLanguage', 'de-DE')">
          <v-list-item-content>
            <v-list-item-title>
              <img src="@/assets/images/flags/de.png" class="mr-2" />
              {{ $t('languages.german') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item @click="$emit('setLanguage', 'en-US')">
          <v-list-item-content>
            <v-list-item-title>
              <img src="@/assets/images/flags/en.png" class="mr-2" />
              {{ $t('languages.english') }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>

      <v-list-item
        @click="$emit('stopImpersonating')"
        v-if="currentUser !== null && currentUser.isImpersonated"
      >
        <v-list-item-icon>
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> {{ $t('logoutAsUser') }} </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$emit('logout')">
        <v-list-item-icon>
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    currentUser: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  data() {
    return {
      settingsMenu: false,
      languageMenu: false
    };
  }
};
</script>
