import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HP_DPT_API + '/hubs/feedback-submitted')
      .configureLogging(LogLevel.Information)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch((err) => {
        console.error('Failed to connect with hub', err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();

    // use new Vue instance as an event bus
    const hub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$feedbackSubmittedHub = hub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('FeedbackSubmitted', (feedbackId, correlationId) => {
      hub.$emit('feedback-submitted', {
        feedbackId,
        correlationId
      });
    });

    hub.joinGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinGroup', correlationId))
        .catch(console.error);
    };

    hub.leaveGroup = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveGroup', correlationId))
        .catch(console.error);
    };
  }
};
