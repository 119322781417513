import emailService from '@/services/EmailService';

const fetchEmails = async ({ commit }, payload) => {
  var emailsResponse = await emailService.getEmails(
    payload?.keyword ?? '',
    payload?.pageIndex ?? 0,
    payload?.pageSize ?? 12,
    payload?.sort ?? 'sendingDateTime',
    payload?.desc ?? true
  );
  commit('setEmails', emailsResponse.emails);
  commit('setEmailsCount', emailsResponse.emailsCount);
};

export default {
  fetchEmails
};
