import api from '@/helpers/api.js';
// import { v4 as uuidv4 } from 'uuid';

export default {
  addPromotionUser(promotionUserData) {
    return api.post('/careerDevelopment/addPromotionUser', promotionUserData);
  },

  async getCareerData(feedbackId) {
    let data = await api.get(`/careerDevelopment/getCareerData/${feedbackId}`);
    return data.result;
  },

  async getComparisonData(feedbackId, processId) {
    let data = await api.get(
      `/careerDevelopment/getComparisonCareerData?feedbackId=${feedbackId}&processId=${processId}`
    );
    return data.result;
  },

  async getPromotionUsers() {
    let data = await api.get(`/promotions`);
    return data.result;
  },

  async setHardfactsState(processId, state) {
    await api.put(`/careerDevelopment/setHardfactsState`, {
      processId: processId,
      state: state
    });
  },

  async setPromotionResult(processId, result) {
    await api.put(`/careerDevelopment/setPromotionResult`, {
      processId: processId,
      confirmed: result
    });
  }
};
