export const access = {
  NONE: 'none',
  READ: 'read',
  WRITE: 'write',
  READ_WRITE: 'read_write'
};

export const roles = {
  USER: 'user',
  PROCESSES: 'processes',
  FORMS: 'forms',
  EMAIL_HISTORY: 'email_history',
  ERROR_LOG: 'error_log',
  NOTIFICATION_TEMPLATES: 'notification_templates',
  TALK_DATES: 'talk_dates'
};
