const getOverviewUsers = (state) => {
  return state.users.map((x) => {
    return {
      id: x.id,
      shorthand: x.shorthand,
      firstname: x.firstName,
      lastname: x.lastName,
      position: x.position,
      supervisorName: x.supervisorName,
      mentorName: x.mentorName,
      canBeImpersonated: x.canBeImpersonated
    };
  });
};

const getEmployeeDetails = (state) => {
  return (shorthand) => {
    const matchingUsers = state.users.filter(
      (x) => x.shorthand.toLowerCase() === shorthand.toLowerCase()
    );

    if (matchingUsers.length === 0) {
      return null;
    }

    return matchingUsers[0];
  };
};

const getPositions = (state) => {
  return state.positions;
};

const getHierarchies = (state) => {
  return state.hierarchies;
};

const getLocations = (state) => {
  return state.locations;
};

const getChildEmployees = (state) => {
  return (employeeId) => {
    return state.users
      .filter((x) => x.supervisorId === employeeId)
      .map((x) => {
        return {
          id: x.id,
          shorthand: x.shorthand,
          firstname: x.firstName,
          lastname: x.lastName,
          position: x.position
        };
      });
  };
};

const getSupervisorOptions = (state) => {
  return state.supervisorOptions;
};

export default {
  getOverviewUsers,
  getEmployeeDetails,
  getPositions,
  getHierarchies,
  getLocations,
  getChildEmployees,
  getSupervisorOptions
};
