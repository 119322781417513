import { mapGetters } from 'vuex';

export const localizationMixin = {
  computed: {
    ...mapGetters({
      workflowSettings: 'workflowSettings'
    }),

    languageKey() {
      let langKey = 'de-DE';
      if (this.$i18n.locale === 'en') {
        langKey = 'en-US';
      }

      return langKey;
    }
  },

  methods: {
    localize(item) {
      if (item === null || item === undefined || item.length === 0) {
        return '';
      }

      if (!Array.isArray(item)) {
        return item;
      }

      const translation = item.find((x) => x.languageKey === this.languageKey);
      return translation?.translation ?? item[0].translation;
    },

    localizeStatus(processType, statusId) {
      const workflow = this.workflowSettings.find(
        (x) => x.processType === processType.toString()
      );
      const status = workflow?.statusList.find(
        (x) => x.statusId === statusId.toString()
      );
      return status?.translations
        ? this.localize(status.translations)
        : this.$t(`dashboard.status.${processType}.${statusId}`);
    },

    localizeStatusDescription(processType, statusId) {
      const workflow = this.workflowSettings.find(
        (x) => x.processType === processType.toString()
      );
      const status = workflow?.statusList.find(
        (x) => x.statusId === statusId.toString()
      );
      return this.localize(status?.descriptions);
    }
  }
};
