import api from '@/helpers/api.js';

export default {
  async buildData() {
    await api.post(`/Dashboard/Build`);
  },

  async getProcesses() {
    return await api.get(`/Dashboard/GetProcesses`);
  },

  async getDates() {
    return await api.get(`/Dashboard/GetDates`);
  },

  async getTasks() {
    return await api.get(`/Dashboard/GetTasks`);
  },

  async setTaskVisibility(data) {
    await api.post(`/Dashboard/SetTaskVisibility`, data);
  },

  async getWidgets() {
    return await api.get('/Dashboard/GetWidgets');
  },

  async saveWidgets(data) {
    var widgetData = JSON.stringify(data);
    await api.post('/Dashboard/SaveWidgets', widgetData);
  }
};
