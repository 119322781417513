import { userEnums } from '@/enums/users.js';

const setMyEmployees = (state, data) => {
  state.users = data;
  state.usersContext = userEnums.userContext.MYEMPLOYEES;
};

const setSupervisors = (state, data) => {
  state.supervisorOptions = data.map((x) => {
    return {
      value: x.id,
      text: `${x.firstName} ${x.lastName}`
    };
  });
};

const setUsers = (state, data) => {
  state.users = data;
  state.usersContext = userEnums.userContext.ADMIN;
};

const setUser = (state, data) => {
  const item = state.users.find((x) => x.id === data.id);

  Object.assign(item, data);
};

export default {
  setMyEmployees,
  setSupervisors,
  setUser,
  setUsers
};
