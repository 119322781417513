<template>
  <v-menu
    v-model="showResults"
    transition="scale-transition"
    offset-y
    content-class="elevation-0"
    :close-on-content-click="false"
    :nudge-top="5"
  >
    <template v-slot:activator="{ args }">
      <v-text-field
        v-model="searchKeyword"
        @keyup="search"
        prepend-inner-icon="mdi-magnify"
        hide-details
        dense
        max-width="200"
        :placeholder="$t('searchApplication')"
        solo
        class="search-field"
        v-bind="args"
      ></v-text-field>
    </template>

    <v-sheet class="search-field-result pa-4">
      <v-skeleton-loader
        v-show="searching"
        loading
        type="paragraph"
      ></v-skeleton-loader>

      <div v-show="!searching && results">
        <v-list>
          <template v-for="item in results">
            <userItem
              v-if="item.type === 'user'"
              :item="item"
              :key="`search_user_${item.shorthand}`"
              @click="showResults = false"
            ></userItem>

            <feedbackItem
              v-if="item.type === 'feedback'"
              :item="item"
              :key="`search_feedback_${item.id}`"
              @click="showResults = false"
            ></feedbackItem>
          </template>
        </v-list>

        <div v-show="numResults > maxInitialResults">
          {{ numResults - maxInitialResults }} {{ $t('searchMoreMatches') }}
        </div>
      </div>

      <v-alert v-show="!searching && results.length === 0" type="warning" text>
        {{ $t('searchNoMatches') }}
      </v-alert>

      <div v-show="!searching" class="text-right">
        <v-btn color="secondary" @click="searchFeedbacks">
          {{ $t('searchMoreResults') }}
        </v-btn>
      </div>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import userItem from './UserSearchResult.vue';
import feedbackItem from './FeedbackSearchResult.vue';

export default {
  data: () => ({
    showResults: false,
    searchKeyword: '',
    results: [],
    userSearch: false,
    fulltextSearch: false,
    debounceTimer: null,
    debounceInterval: 500,
    maxInitialResults: 5,
    numResults: -1
  }),

  computed: {
    ...mapGetters({
      users: 'users/getOverviewUsers'
    }),

    searching() {
      return this.userSearch || this.fulltextSearch;
    }
  },

  methods: {
    ...mapActions({
      fetchUsers: 'users/fetchUsers',
      searchFeedbackValues: 'feedbacks/searchFeedbackValues'
    }),

    getMatchingIndex(searchText) {
      if (
        searchText.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) >= 0
      ) {
        return (this.searchKeyword.length * 100) / searchText.length;
      }

      return 0;
    },

    async search(event) {
      if (event.key.toLowerCase() === 'enter') {
        await this.searchFeedbacks();
      } else {
        await this.searchUsers();
      }
    },

    async searchUsers() {
      if (this.searchKeyword.length < 2) {
        this.showResults = false;
        return;
      }

      this.showResults = true;
      this.userSearch = true;
      this.results = [];
      this.numResults = -1;

      if (this.debounceTimer !== null) {
        clearTimeout(this.debounceTimer);
      }

      this.debounceTimer = setTimeout(() => {
        this.performSearch();
      }, this.debounceInterval);
    },

    async performSearch() {
      if (this.searchKeyword === '') {
        return;
      }

      let allUsers = this.users;
      if (!allUsers || allUsers.length === 0) {
        await this.fetchUsers();
        allUsers = this.users;
      }

      const allResults = allUsers.filter(
        (x) =>
          (x.firstname + ' ' + x.lastname)
            .toLowerCase()
            .indexOf(this.searchKeyword.toLowerCase()) >= 0 ||
          x.shorthand.toLowerCase().indexOf(this.searchKeyword.toLowerCase()) >=
            0
      );

      this.numResults = allResults.length;

      const matchingData = allResults
        .map((x) => {
          return {
            type: 'user',
            firstName: x.firstname,
            lastName: x.lastname,
            supervisorName: x.supervisorName,
            shorthand: x.shorthand,
            shorthandIndex: this.getMatchingIndex(x.shorthand),
            nameIndex: this.getMatchingIndex(x.firstname + ' ' + x.lastname)
          };
        })
        .filter((x) => x.shorthandIndex > 0 || x.nameIndex > 0)
        .sort((a, b) => {
          return (
            Math.max(a.shorthandIndex, a.nameIndex) <
            Math.max(b.shorthandIndex, b.nameIndex)
          );
        })
        .slice(0, this.maxInitialResults);

      this.results = [...this.results, ...matchingData];

      this.userSearch = false;
    },

    async searchFeedbacks() {
      this.fulltextSearch = true;

      const additionalResults = await this.searchFeedbackValues(
        this.searchKeyword
      );

      const matchingData = additionalResults.results.map((x) => {
        return {
          type: 'feedback',
          recipient: x.recipient,
          donor: x.donor,
          id: x.feedbackId,
          processType: x.processType,
          feedbackType: x.feedbackType
        };
      });

      this.results = [...this.results, ...matchingData];

      this.fulltextSearch = false;
    }
  },

  components: {
    userItem,
    feedbackItem
  }
};
</script>
