export const feedbackEnums = Object.freeze({
  feedbackTypes: {
    SELF_EVALUATION: 1,
    MANAGER_EVALUATION: 2,
    DOCUMENTATION: 3,
    COMPARISON: 4
  },

  processTypes: {
    MAFEG: 1,
    PDP: 2,
    EHG: 3,
    PROMOTION: 4,
    PDC: 5,
    EDC: 6
  },

  feedbackStatus: {
    OPEN: 100,
    IN_PROGRESS: 150,
    SUBMITTED: 200,
    APPROVED: 300,
    UNCHANGED: 1000
  },

  valueState: {
    CREATED: 1,
    COPIED: 2,
    COPIED_AND_UPDATED: 3,
    COPYING: 4
  }
});
