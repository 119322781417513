const getStatusList = (state) => state.statusList;
const getRoles = (state) => state.allRoles;
const getFeedbackTypes = (state) => state.allFeedbackTypes;
const getConclusionTypes = (state) => state.allConclusionTypes;
const getStatusMapping = (state) => state.statusMapping;
const getProcessCreators = (state) => {
  return function (procesType) {
    const creators = state.processCreator.filter(
      (x) => x.processType === procesType
    );

    if (creators.length === 0) {
      return [];
    }

    return creators[0].creators;
  };
};

export default {
  getStatusList,
  getRoles,
  getFeedbackTypes,
  getConclusionTypes,
  getStatusMapping,
  getProcessCreators
};
