export const workflows = {
  routes: [
    {
      path: '/:slug/admin/workflows',
      name: 'Workflows',
      meta: {
        breadcrumbs: [
          { label: 'admin', icon: 'star' },
          { label: 'workflows.title' }
        ],
        title: 'workflows.title'
      },
      component: () => import('../views/Workflows/my-workflows.vue')
    },
    {
      path: '/:slug/admin/workflows/:id',
      name: 'Workflow',
      meta: {
        breadcrumbs: [
          { label: 'admin', icon: 'star' },
          { label: 'workflows.title' }
        ],
        title: 'workflows.title'
      },
      component: () => import('../views/Workflows/my-workflow.vue')
    }
  ]
};
