import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import styles
import 'tiptap-vuetify/dist/main.css';

const vuetify = new Vuetify();

Vue.use(Vuetify);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
});

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        background: '#F2F3F8',
        primary: '#002776',
        secondary: '#009fda'
      }
    }
  }
});
