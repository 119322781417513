<template>
  <v-menu
    v-model="notificationsMenu"
    bottom
    left
    offset-y
    min-width="500"
    max-width="500"
    transition="slide-y-transition"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on: onMenu, attrs }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on: onTooltip }">
          <v-btn
            text
            v-bind="attrs"
            v-on="{ ...onMenu, ...onTooltip }"
            height="100%"
          >
            <v-badge
              :content="newNotificationsCount"
              :value="newNotificationsCount"
              color="red"
              overlap
            >
              <v-icon>mdi-bell</v-icon>
            </v-badge>
          </v-btn>
        </template>
        <span>{{ $t('notifications') }}</span>
      </v-tooltip>
    </template>

    <v-list dense>
      <v-list-item v-if="!notifications || notifications.length === 0">
        <v-list-item-content>
          <i class="muted">{{ $t('noNotifications') }}</i>
        </v-list-item-content>
      </v-list-item>

      <v-list-item v-for="notification in notifications" :key="notification.id">
        <v-list-item-icon class="mt-3 mr-3">
          <v-icon v-if="notification.type == 0">mdi-alert-circle</v-icon>
          <v-icon v-if="notification.type == 1">mdi-email</v-icon>
        </v-list-item-icon>
        <v-list-item-content
          v-if="!notification.seen"
          style="font-size: 10pt; font-weight: bold"
          >{{ notification.message }}</v-list-item-content
        >
        <v-list-item-content v-if="notification.seen" class="muted">{{
          notification.message
        }}</v-list-item-content>

        <v-list-item-action>
          <v-btn text small @click="setNotificationSeen(notification.id)">
            <v-icon>mdi-eye-off</v-icon>
          </v-btn>

          <v-btn
            text
            small
            v-if="notification.routeData"
            @click="navigateToRoute(notification)"
          >
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => ({
    notificationsMenu: false
  }),

  computed: {
    ...mapGetters({
      notifications: 'notifications',
      newNotificationsCount: 'newNotificationsCount'
    })
  },

  methods: {
    ...mapActions({
      setNotificationSeen: 'setNotificationSeen'
    }),

    navigateToRoute(notification) {
      this.notificationsMenu = false;
      this.setNotificationSeen(notification.id);

      if (notification.routeData.id) {
        this.$router.push({
          name: notification.routeData.routeName,
          params: { id: notification.routeData.id }
        });
      } else {
        this.$router.push({ name: notification.routeData.routeName });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-list-item__action--stack {
  flex-direction: row;

  .v-btn {
    padding: 10px;
    min-width: 30px;
  }
}
</style>
