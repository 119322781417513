import api from '@/helpers/api.js';

export default {
  initialize: () => api.get('/Session/Initialize'),
  // TODO: remove userid parameter with redis implementation
  setLanguage: (userId, languageKey) =>
    api.post('/Session/SetLanguage', {
      userId: userId,
      languageKey: languageKey
    }),

  impersonateUser(userId) {
    api.post('/session/impersonate-user', userId);
  },

  async unimpersonateUser() {
    await api.post('/session/unimpersonate-user');
  }
};
