export default {
  statuses: {
    openStatus: 0,
    preparationStatus: 10,
    documentationStatus: 20,
    closedStatus: 99,
    reviewRequiredStatus: 110,
    reviewSetStatus: 120,
    reviewElapsedStatus: 199
  },
  processes: [],
  processesUpdated: true,
  dates: [],
  datesUpdated: true,
  tasks: [],
  tasksUpdated: true,
  widgets: []
};
