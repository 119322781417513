import api from '@/helpers/api.js';
// import { v4 as uuidv4 } from 'uuid';
const apiUrl = process.env.VUE_APP_ARCHIVE_API_URL;

export default {
  async approveFeedback(feedbackId) {
    return await api.put('/Feedbacks/Approve', {
      feedbackId
    });
  },

  copyProcess(processId, correlationId) {
    api.post(`/Feedbacks/CopyProcess`, { processId, correlationId });
  },

  createAnonymousFeedback(email, formId) {
    return api.post('/feedbacks/share', {
      email: email,
      formId: formId
    });
  },

  createFeedback(feedbackData) {
    api.post('/Feedbacks', feedbackData);
  },

  createPdp(employeeId) {
    api.post('/Pdp/create', employeeId);
  },

  async deleteTableItem(itemData) {
    return await api.delete(`/FeedbackTableItem`, itemData);
  },

  async exportTalkDate(processId) {
    return await api.get(`/Feedbacks/ExportTalkDate`, {
      processId: processId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  },

  async getFieldValue(recipientId, elementData) {
    for (let i = 0; i < elementData.length; i++) {
      elementData[i].recipientId = recipientId;
    }

    return await api.post(`/Feedbacks/fieldValue`, elementData);
  },

  async importArchive(shorthand) {
    var shorthandEncoded = window.btoa(shorthand);

    const requestUrl = `${apiUrl}/archive/${shorthandEncoded}`;
    const response = await fetch(requestUrl, {
      method: 'get'
    }).catch(function (error) {
      console.log(error);
      return {
        isAvailable: false
      };
    });

    if (response.ok) {
      const json = await response.json();
      json.conclusionUrl = apiUrl + json.conclusionUrl;
      json.selfEvaluationUrl = apiUrl + json.selfEvaluationUrl;
      json.supervisorEvaluationUrl = apiUrl + json.supervisorEvaluationUrl;
      json.comparisonUrl = apiUrl + json.comparisonUrl;

      return json;
    } else {
      return {
        isAvailable: false
      };
    }
  },

  async listDevelopmentPositions() {
    const data = await api.get(`/CareerDevelopment/GetPositions`);
    return data.positions;
  },

  async listFeedbackStatusOptions() {
    return await api.get(`/Feedbacks/GetStatusOptions`);
  },

  async listFeedbacksAsRecipient(employeeId) {
    let data = null;

    if (employeeId) {
      data = await api.get(`/Feedbacks/list/recipient/${employeeId}`);
    } else {
      data = await api.get(`/Feedbacks/list/recipient`);
    }

    return data.result;
  },

  async listFeedbacksAsDonor() {
    let data = await api.get(`/Feedbacks/list`);
    return data.result;
  },

  async listAllFeedbacks() {
    let data = await api.get(`/Feedbacks/list`);
    return data.result;
  },

  async listFeedbacksFromHierarchy() {
    let data = await api.get(`/Feedbacks/list/hierarchy`);
    return data.result;
  },

  async listProcesses(targetUserId) {
    let data = await api.get(`/Process/${targetUserId}`);
    return data;
  },

  async listPdcEdc(talkroundId) {
    let endpoint = `/Feedbacks/listPdcEdc`;
    if (talkroundId) {
      endpoint += `?talkroundId=${talkroundId}`;
    }

    const data = await api.get(endpoint);
    return data.result;
  },

  async loadAnonymousFeedback(feedbackId, accessToken) {
    return await api.get(
      `/Feedbacks?feedbackId=${feedbackId}&accessToken=${accessToken}`
    );
  },

  async loadFeedback(feedbackId) {
    return await api.get(`/Feedbacks?feedbackId=${feedbackId}`);
  },

  async loadReviewData(feedbackId, elementName) {
    return api.get('/feedbacks/values/GetReviewData', {
      feedbackId,
      elementName
    });
  },

  async saveCareerData(
    feedbackId,
    feedbackType,
    targetPosition,
    targetDate,
    formData
  ) {
    return await api.post(`/CareerDevelopment`, {
      feedbackId,
      feedbackType,
      targetPosition,
      targetDate,
      Data: JSON.stringify(formData)
    });
  },

  async saveComment(feedbackId, elementId, itemId, comment) {
    return await api.post(`/FeedbackComments`, {
      feedbackId,
      elementId,
      itemId,
      comment
    });
  },

  async saveTableItem(itemData) {
    return await api.post(`/FeedbackTableItem`, itemData);
  },

  async saveTalkDate(talkDateData) {
    return await api.post(`/FeedbackTalkDate`, talkDateData);
  },

  async saveTarget(targetData) {
    return await api.post(`/Feedbacks/Targets/SaveTarget`, targetData);
  },

  async saveMatrixItemValue(feedbackId, elementId, itemId, value) {
    return await api.post(`/feedbackValues/matrix`, {
      feedbackId,
      elementId,
      itemId,
      value
    });
  },

  async saveItemValue(feedbackId, elementId, value) {
    return await api.post(`/feedbackValues/item`, {
      feedbackId,
      elementId,
      value
    });
  },

  async searchFeedbackValues(keyword) {
    return await api.get(`/fulltextsearch/${keyword}`);
  },

  setFeedbackInProgress(feedbackId) {
    api.put(`/Feedbacks/SetInProgress`, {
      feedbackId
    });
  },

  async submitFeedback(feedbackId) {
    return await api.post('/Feedbacks/Submit', {
      feedbackId
    });
  },

  async vetoFeedback(feedbackId, comment) {
    return await api.put('/Feedbacks/Veto', {
      feedbackId,
      comment
    });
  },

  async setProcessStatus(processId, statusId) {
    return await api.post('/Feedbacks/SetProcessStatus', {
      processId,
      statusId
    });
  },

  async setProcessForm(processId, formId) {
    return await api.post('/Feedbacks/SetProcessForm', {
      processId,
      formId
    });
  },

  async getTargets(userId) {
    return await api.get('/Feedbacks/Targets/GetTargets', { userId });
  },

  async deleteTarget(targetId) {
    return await api.post(`/Feedbacks/Targets/DeleteTarget/${targetId}`);
  }
};
