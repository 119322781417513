import errorLogService from '@/services/ErrorLogService.js';

const fetchErrors = async ({ state }) => {
  const errors = await errorLogService.getErrors();
  state.errors = errors;

  return errors;
};

export default {
  fetchErrors
};
