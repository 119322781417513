const filters = {
  highlight: function (value, highlightText) {
    if (
      !value ||
      highlightText === null ||
      highlightText === undefined ||
      highlightText.trim() === ''
    ) {
      return value;
    }

    if (value.toLowerCase().indexOf(highlightText.toLowerCase()) > -1) {
      var reg = new RegExp('(' + highlightText + ')', 'gi');
      return value.replace(reg, '<span class="highlighted">$1</span>');
    }

    if (highlightText.trim().indexOf(' ') <= 0) {
      return value;
    }

    const highlightedWords = highlightText.split(' ');
    for (const word of highlightedWords) {
      if (value.toLowerCase().indexOf(word.toLowerCase()) > -1) {
        var wordReg = new RegExp('(' + word + ')', 'gi');
        return value.replace(wordReg, '<span class="highlighted">$1</span>');
      }
    }

    return value;
  }
};

export { filters };
