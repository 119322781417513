import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HP_DPT_API + '/organization-hub')
      .configureLogging(LogLevel.Information)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch((err) => {
        console.error('Failed to connect with hub', err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();

    // use new Vue instance as an event bus
    const organizationHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$organizationHub = organizationHub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on(
      'WorkflowSettingsModified',
      (workflowSettingsId, organizationId) => {
        organizationHub.$emit('workflows-modified', {
          workflowSettingsId,
          organizationId
        });
      }
    );

    connection.on('FeedbackProcessesUpdated', () => {
      organizationHub.$emit('dashboard-processes-updated');
    });

    organizationHub.joinOrganization = (organizationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinOrganizationGroup', organizationId))
        .catch(console.error);
    };

    organizationHub.leaveOrganization = (organizationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveOrganizationGroup', organizationId))
        .catch(console.error);
    };
  }
};
