<template>
  <v-sheet flat class="pa-4 pb-0 v-sheet--page-title">
    <h1 color="accent" class="text-uppercase">
      {{ $t($route.meta.title) }}
    </h1>

    <v-breadcrumbs :items="items" divider=">" class="pl-0" :key="iteration">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>

      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :disabled="item.disabled" :to="item.to">
          <template v-if="item.label !== undefined">
            {{ $t(item.label) }}
          </template>
          <template v-else-if="item.text !== undefined">
            {{ item.text }}
          </template>
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    iteration: 0,
    items: []
  }),

  watch: {
    '$route.meta.breadcrumbs': {
      handler(breadcrumbs) {
        if (breadcrumbs === null || breadcrumbs === undefined) {
          return [];
        }

        this.items = breadcrumbs.map((x) => {
          return {
            label: x.label,
            disabled: x.to === undefined || x.to === null,
            icon: x.icon,
            text: x.text,
            to: x.to
          };
        });
      },
      deep: true,
      immediate: true
    }
  }
};
</script>
