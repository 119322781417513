const getEmails = (state) => {
  return state.emails.map((x) => {
    return {
      id: x.id,
      sender: x.sender,
      recipient: x.recipient,
      subject: x.subject,
      sendingDateTime: x.sendingDateTime,
      status: x.status
    };
  });
};

const getEmailsCount = (state) => state.emailsCount;

const getStatus = (state) => {
  return state.emails
    .map((x) => x.status)
    .sort((a, b) => {
      if (a.toLowerCase() < b.toLowerCase()) {
        return -1;
      } else if (a.toLowerCase() > b.toLowerCase()) {
        return 1;
      } else {
        return 0;
      }
    });
};

export default {
  getEmails,
  getEmailsCount,
  getStatus
};
