<template>
  <div>
    <v-system-bar color="deep-purple darken-3"></v-system-bar>

    <v-app-bar flat app fixed clipped-left>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <img
        :src="require(`@/assets/${organizationSlug}-logo.png`)"
        style="height: 80px"
        v-if="$vuetify.breakpoint.mdAndUp && organizationSlug"
      />

      <v-spacer></v-spacer>

      <fulltext-search />

      <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
        <template v-slot:activator="{ on }">
          <v-btn
            text
            small
            href="mailto:feedback@feedbackweb.atlassian.net"
            v-on="on"
            height="100%"
          >
            <v-icon>mdi-message-alert</v-icon>
          </v-btn>
        </template>

        <span>{{ $t('giveFeedback') }}</span>
      </v-tooltip>

      <notifications></notifications>

      <theme-switch
        v-if="$vuetify.breakpoint.mdAndUp"
        :custom-colors="false"
      ></theme-switch>

      <desktop-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        :currentUser="currentUser"
        @logout="logout"
        @setLanguage="selectLanguage"
        @stopImpersonating="stopImpersonating"
      ></desktop-menu>

      <mobile-menu
        v-if="$vuetify.breakpoint.smAndDown"
        :currentUser="currentUser"
        @logout="logout"
        @setLanguage="selectLanguage"
        @stopImpersonating="stopImpersonating"
      ></mobile-menu>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app left clipped :width="drawerWidth">
      <v-list
        two-line
        dense
        elevation="3"
        class="ma-4 mt-6 v-list--avatar error"
      >
        <v-list-item :to="{ name: 'Profile' }">
          <v-list-item-content>
            <v-list-item-subtitle
              v-if="isImpersonated"
              class="white--text font-weight-regular mb-2"
            >
              {{ $t('loggedInAs') }}
            </v-list-item-subtitle>
            <v-list-item-title class="white--text avatar-title">
              {{ firstName + ' ' + lastName }}
            </v-list-item-title>
            <v-list-item-subtitle class="white--text font-weight-regular">
              {{ $t('showProfile') }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <profile-avatar
            size="xl"
            :picture="picture"
            :firstName="firstName"
            :lastName="lastName"
            :email="email"
          ></profile-avatar>
        </v-list-item>
      </v-list>

      <div class="overline pl-4 mt-6 font-weight-bold">Navigation</div>

      <v-list nav dense>
        <v-list-item-group v-model="group">
          <v-list-item :to="{ name: 'Dashboard' }" exact>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Processes' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-comment-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('processes') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="organizationSlug === 'hays'" :to="{ name: 'PDC' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-comment-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>PDC/EDC</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Targets' }">
            <v-list-item-icon>
              <v-icon>mdi-bullseye-arrow</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('targets.menuItem')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'UserOverview' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('employees') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item :to="{ name: 'Reporting' }">
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reporting.title') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="hasRole(roleTypes.FORMS)"
            :to="{ name: 'FormsOverview' }"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-clipboard-text</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('forms.forms') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="hasRole(roleTypes.EMAIL_HISTORY)"
            :to="{ name: 'EmailOverview' }"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-email-fast</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('emailsOverview') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="hasRole(roleTypes.NOTIFICATION_TEMPLATES)"
            :to="{ name: 'NotificationTemplates' }"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-email-edit</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('notificationTemplates.menuItem')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="hasRole(roleTypes.ERROR_LOG)"
            :to="{ name: 'ErrorLog' }"
          >
            <v-list-item-icon class="mr-4">
              <v-icon>mdi-alert-circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{
                $t('errorLog.menuItem')
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-row>
        <v-col cols="12">
          <v-container fluid class="pa-2 pt-0 pa-sm-2 pt-sm-0 pa-md-6 pt-md-0">
            <page-title></page-title>
          </v-container>
          <v-container fluid class="pa-2 pt-0 pa-sm-2 pt-sm-0 pa-md-6 pt-md-0">
            <slot />
          </v-container> </v-col
      ></v-row>
    </v-main>
  </div>
</template>

<script>
import pageTitle from '@/components/main/PageTitle';
import profileAvatar from '@/components/main/ProfileAvatar';
import themeSwitch from '@/components/theme-switch';
import fulltextSearch from '@/components/main/FulltextSearch';
import notifications from '@/components/main/Notifications.vue';
import desktopMenu from '@/components/main/DesktopTopMenu.vue';
import mobileMenu from '@/components/main/MobileTopMenu.vue';

import AuthService from '@/services/AuthService.js';
import FileService from '@/services/FileService.js';

import { mapActions, mapGetters } from 'vuex';
import { access, roles } from '@/constants/access.js';

export default {
  data() {
    return {
      settingsMenu: false,
      languageMenu: false,
      drawerWidth: 280,
      authService: null,
      drawer: true,
      group: null,
      group2: null,
      languages: [
        { value: 'de-DE', label: 'Deutsch' },
        { value: 'en-US', label: 'English' }
      ]
    };
  },

  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      currentOrganization: 'currentOrganization',
      languageKey: 'languageKey'
    }),

    organizationSlug() {
      return this.currentOrganization?.slug;
    },

    picture() {
      return this.currentUser?.picture ?? '';
      // return 'https://cdn.vuetifyjs.com/images/john.png';
    },
    firstName() {
      return this.currentUser?.firstName ?? '';
    },
    lastName() {
      return this.currentUser?.lastName ?? '';
    },

    email() {
      return this.currentUser?.email ?? '';
    },

    shorthand() {
      return this.currentUser?.shorthand ?? '';
    },

    isAdmin() {
      return this.currentUser?.isAdmin ?? false;
    },

    isSupervisor() {
      return this.currentUser?.isSupervisor ?? false;
    },

    isFeedbackDonor() {
      return this.currentUser?.isFeedbackDonor ?? false;
    },

    isImpersonated() {
      return this.currentUser?.isImpersonated ?? false;
    },

    hasMafegProcess() {
      return this.currentUser?.hasMafegProcess;
    },

    selectedLanguage: {
      get() {
        return this.languageKey;
      },
      set(value) {
        this.setLanguage(value);
        this.$i18n.locale = value.substring(0, value.indexOf('-'));
        this.settingsMenu = false;
        this.languageMenu = false;
      }
    },

    logoUrl() {
      return FileService.getPublicFilePath(`${this.organizationSlug}-logo.png`);
    },

    roleTypes() {
      return roles;
    }
  },

  mounted() {
    this.authService = new AuthService();
  },

  methods: {
    ...mapActions({
      setLanguage: 'setLanguage',
      unimpersonateUser: 'unimpersonateUser'
    }),

    async logout() {
      if (this.isImpersonated) {
        this.unimpersonateUser();

        setTimeout(async () => {
          this.$organizationHub.leaveOrganization(this.currentOrganization.id);
          await this.authService.logout();
        }, 200);
      } else {
        this.$organizationHub.leaveOrganization(this.currentOrganization.id);
        await this.authService.logout();
      }
    },

    selectLanguage(languageKey) {
      this.selectedLanguage = languageKey;
    },

    async stopImpersonating() {
      try {
        await this.unimpersonateUser();
      } catch (e) {
        console.log(e);
      }

      if (this.$route.name.toLowerCase() !== 'Dashboard') {
        this.$router.push({ name: 'Dashboard' });
      }

      setTimeout(() => window.location.reload(), 1000);
    },

    hasRole(roleName) {
      const accessType = this.$store.getters.getAccess(roleName);
      return accessType !== access.NONE;
    }
  },

  components: {
    pageTitle,
    profileAvatar,
    themeSwitch,
    fulltextSearch,
    notifications,
    desktopMenu,
    mobileMenu
  }
};
</script>
FulltextSearch

<style lang="scss" scoped>
.avatar-title {
  font-weight: bold !important;
  font-size: 1rem !important;
}

.v-list-item__subtitle {
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

.overline {
  font-size: 0.875rem !important;
}
</style>
