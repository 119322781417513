import api from '@/helpers/api.js';
// import { v4 as uuidv4 } from 'uuid';

export default {
  async setReview(reviewDate) {
    if (reviewDate === null) {
      return;
    }

    let data = await api.post('/Pdp/SetReviewDate', { reviewDate: reviewDate });
    return data.result;
  },

  async downloadFile(downloadUrl) {
    const file = await api.download(downloadUrl);
    return file;
  },

  async exportReviewDate(processId) {
    return await api.get(`/pdp/exportReviewDate`, {
      processId: processId,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  },

  async getAsRecipient() {
    let data = await api.get(`/Pdp`);
    return data.result;
  },

  async getAsSupervisor(userId) {
    let data = await api.get(`/Pdp/${userId}`);
    return data.result;
  },

  async listAll() {
    let data = await api.get(`/Pdp/List`);
    return data.result;
  },

  async listFromHierarchy() {
    let data = await api.get(`/Pdp/List/Hierarchy`);
    return data.result;
  },

  async uploadDocument(file) {
    let data = await api.postFile(`/Pdp`, file);
    return data.result;
  }
};
