const setEmails = (state, emails) => {
  state.emails = emails;
};

const setEmailsCount = (state, emailsCount) => {
  state.emailsCount = emailsCount;
};

export default {
  setEmails,
  setEmailsCount
};
