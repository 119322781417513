export const reportingRoutes = {
  routes: [
    {
      path: '/:slug/reporting',
      name: 'Reporting',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.title'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-overview" */
          '../views/reporting//Overview.vue'
        )
    },
    {
      path: '/:slug/reporting/status-report/:reportType/:processType',
      name: 'status-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.statusReportTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-status-report" */
          '../views/reporting/StatusReport.vue'
        )
    },
    {
      path: '/:slug/reporting/pdp-report',
      name: 'pdp-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.pdpReportTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-pdp-report" */
          '../views/reporting/PdpReport.vue'
        )
    },
    {
      path: '/:slug/reporting/field-value-report/:reportType/:processType',
      name: 'development-measures-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.developmentMeasuresReportTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-dev-measures-report" */
          '../views/reporting/FieldValueReport.vue'
        )
    },
    {
      path: '/:slug/reporting/career-development',
      name: 'career-development-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.developmentMeasuresReportTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-feedback-content-report" */
          '../views/reporting/CareerDevelopmentReport.vue'
        )
    },
    {
      path: '/:slug/reporting/pdc-actions',
      name: 'pdc-actions-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.pdcActionsTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-pdc-actions-report" */
          '../views/reporting/ActionsReport.vue'
        )
    },
    {
      path: '/:slug/reporting/ehg-actions',
      name: 'ehg-targets-report',
      meta: {
        breadcrumbs: [
          {
            label: 'reporting.title',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'reporting.ehgActionsTitle'
      },
      component: () =>
        import(
          /* webpackChunkName: "reporting-ehg-actions-report" */
          '../views/reporting/EhgActionsReport.vue'
        )
    }
  ]
};
