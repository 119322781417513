<template>
  <div id="profile" :class="className">
    <span v-if="picture === ''">{{ initials }}</span>
    <img v-else :src="picture" class="avatar-image" :alt="initials" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'xl'
    },
    picture: {
      type: String,
      default: ''
    },
    email: {
      type: String,
      defaul: ''
    },
    firstName: {
      type: String,
      default: ''
    },
    lastName: {
      type: String,
      default: ''
    }
  },
  computed: {
    initials() {
      if (this.firstName != '') {
        return (
          this.firstName.split('')[0] +
          (this.lastName != '' ? this.lastName.split('')[0] : '')
        );
      } else if (this.email != null && this.email != '') {
        return this.email.split('')[0];
      } else {
        return 'JD';
      }
    },
    bcNumber() {
      const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');
      const initialsArray = this.initials.split('');

      var bcNumber = 0;
      initialsArray.forEach((x) => {
        bcNumber += alphabet.indexOf(x.toString());
      });

      return Math.floor(bcNumber / initialsArray.length);
    },

    className() {
      let cName = 'bc-' + this.bcNumber + ' size-' + this.size;

      if (this.picture !== '') {
        cName += ' has-picture';
      }

      return cName;
    }
  }
};
</script>

<style lang="scss" scoped>
#profile {
  border: 1px solid #ccc;
  background-size: contain;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  font-size: 1.5em;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  &.has-picture {
    border: none;
  }

  img {
    width: auto;
    height: 100%;
    margin: 0 auto;
    display: inline;
  }

  &.yellow-30,
  &.bc-1 {
    background-color: #dba617;
    color: #333;
  }

  &.gray-50,
  &.bc-2 {
    background-color: #646970;
    color: #fff;
  }

  &.blue-50,
  &.bc-3 {
    background-color: #2271b1;
    color: #fff;
  }

  &.red-50,
  &.bc-4 {
    background-color: #d63638;
    color: #fff;
  }

  &.yellow-50,
  &.bc-5 {
    background-color: #996b00;
    color: #fff;
  }

  &.green-50,
  &.bc-6 {
    background-color: #008a20;
    color: #fff;
  }

  &.gray-60,
  &.bc-7 {
    background-color: #50575e;
    color: #fff;
  }

  &.blue-60,
  &.bc-8 {
    background-color: #135e96;
    color: #fff;
  }

  &.red-60,
  &.bc-9 {
    background-color: #b32d2e;
    color: #fff;
  }

  &.yellow-60,
  &.bc-10 {
    background-color: #755100;
    color: #fff;
  }

  &.green-60,
  &.bc-11 {
    background-color: #007017;
    color: #fff;
  }

  &.gray-70,
  &.bc-12 {
    background-color: #3c434a;
    color: #fff;
  }

  &.blue-70,
  &.bc-13 {
    background-color: #0a4b78;
    color: #fff;
  }

  &.red-70,
  &.bc-14 {
    background-color: #8a2424;
    color: #fff;
  }

  &.yellow-70,
  &.bc-15 {
    background-color: #614200;
    color: #fff;
  }

  &.green-70,
  &.bc-16 {
    background-color: #005c12;
    color: #fff;
  }

  &.gray-80,
  &.bc-17 {
    background-color: #2c3338;
    color: #fff;
  }

  &.blue-80,
  &.bc-18 {
    background-color: #043959;
    color: #fff;
  }

  &.red-80,
  &.bc-19 {
    background-color: #691c1c;
    color: #fff;
  }

  &.yellow-80,
  &.bc-20 {
    background-color: #4a3200;
    color: #fff;
  }

  &.green-80,
  &.bc-21 {
    background-color: #00450c;
    color: #fff;
  }

  &.gray-90,
  &.bc-22 {
    background-color: #1d2327;
    color: #fff;
  }

  &.blue-90,
  &.bc-23 {
    background-color: #01263a;
    color: #fff;
  }

  &.red-90,
  &.bc-24 {
    background-color: #451313;
    color: #fff;
  }

  &.yellow-90,
  &.bc-25 {
    background-color: #362400;
    color: #fff;
  }

  &.green-90,
  &.bc-26 {
    background-color: #003008;
    color: #fff;
  }

  &.size-lg {
    height: 39px;
    width: 39px;
    font-size: 19px;
    padding-top: 6px;
    padding-left: 7px;
  }

  &.size-md {
    height: 33px;
    width: 33px;
    font-size: 16px;
    padding-top: 5px;
    padding-left: 5px;
  }

  &.size-sm {
    height: 28px;
    width: 28px;
    font-size: 13px;
    padding-top: 4px;
    padding-left: 5px;
  }

  &.size-xs {
    height: 24px;
    width: 24px;
    font-size: 11px;
    padding-top: 4px;
    padding-left: 4px;
  }
}
</style>
