const setFormsListItems = (state, data) => {
  state.listItems = data;
};

const setCustomSettings = (state, data) => {
  state.customSettings = data;
};

export default {
  setFormsListItems,
  setCustomSettings
};
