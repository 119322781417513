<template>
  <v-list-item
    :to="{
      name: 'FeedbackForm',
      params: { id: item.id }
    }"
    @click="$emit('click')"
  >
    <v-list-item-content>
      <v-list-item-title
        >{{ $t(`feedbacks.processTypes.${item.processType}`) }} &ndash;
        {{ feedbackTitle }}</v-list-item-title
      >
      <v-list-item-subtitle>
        {{ $t('feedbacks.recipient') }}:
        {{ item.recipient }}

        <br />

        {{ $t('feedbacks.donor') }}:
        {{ item.donor }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    feedbackTitle() {
      if (this.item.title?.length > 0 ?? false) {
        return this.localize(this.item.title);
      }

      return this.$t(`feedbacks.typeOptions.${this.item.feedbackType}`);
    }
  }
};
</script>
