export const targets = {
  routes: [
    {
      path: '/:slug/targets',
      name: 'Targets',
      meta: {
        breadcrumbs: [
          { label: 'feedbacks.feedbacks', icon: 'comment' },
          { label: 'targets.targets' }
        ],
        title: 'targets.targetsOverview'
      },
      component: () => import('../views/Targets/targets.vue')
    }
  ]
};
