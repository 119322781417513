import userService from '@/services/UserService';
import historyService from '@/services/UserHistoryService';
import { userEnums } from '@/enums/users.js';

const fetchMyEmployees = async ({ state, commit }) => {
  if (
    state.users.length === 0 ||
    state.usersContext !== userEnums.userContext.MYEMPLOYEES
  ) {
    var userData = await userService.getMyEmployees();
    commit('setMyEmployees', userData);
  }

  commit('setSupervisors', state.users);
};

const fetchOptions = async ({ state }) => {
  if (
    !state.hierarchies.length === 0 ||
    state.positions.length === 0 ||
    state.locations.length === 0
  ) {
    const data = await userService.getDataOptions();
    (state.hierarchies = data.hierarchy),
      (state.locations = data.location),
      (state.positions = data.position);
  }
};

const fetchUserDetails = async (context, shorthand) => {
  return userService.getUserDetails(shorthand);
};

const fetchUsers = async ({ state, commit }) => {
  if (state.users.length === 0) {
    var userData = await userService.getUsers();
    commit('setUsers', userData.users);
  }

  commit('setSupervisors', state.users);
};

const fetchUserHistory = async (context, userId) => {
  const history = await historyService.getHistory(userId);
  return history;
};

export default {
  fetchMyEmployees,
  fetchOptions,
  fetchUserDetails,
  fetchUsers,
  fetchUserHistory
};
