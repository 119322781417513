import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HP_DPT_API + '/feedbacks-hub')
      .configureLogging(LogLevel.Information)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch((err) => {
        console.error('Failed to connect with hub', err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();

    // use new Vue instance as an event bus
    const feedbacksHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$feedbacksHub = feedbacksHub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('FeedbackProcessCreated', (processId, correlationId) => {
      feedbacksHub.$emit('feedback-process-created', {
        processId,
        correlationId
      });
    });

    connection.on('FeedbackProcessUpdated', (correlationId) => {
      feedbacksHub.$emit('feedback-process-updated', correlationId);
    });

    feedbacksHub.enterCreateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinCreateGroup', correlationId))
        .catch(console.error);
    };

    feedbacksHub.leaveCreateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveCreateGroup', correlationId))
        .catch(console.error);
    };

    feedbacksHub.enterUpdateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinUpdateGroup', correlationId))
        .catch(console.error);
    };

    feedbacksHub.leaveUpdateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveUpdateGroup', correlationId))
        .catch(console.error);
    };
  }
};
