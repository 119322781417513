import api from '@/helpers/api.js';

export default {
  async listTalkrounds(filters) {
    if (filters) {
      const data = await api.get('/Talkrounds', filters);
      return data.result;
    } else {
      const data = await api.get('/Talkrounds');
      return data.result;
    }
  }
};
