const setProcessesUpdated = (state, updated) => {
  state.processesUpdated = updated;
};

const setProcesses = (state, processes) => {
  state.processes = processes;
};

const setDatesUpdated = (state, updated) => {
  state.datesUpdated = updated;
};

const setDates = (state, dates) => {
  state.dates = dates;
};

const setTasksUpdated = (state, updated) => {
  state.tasksUpdated = updated;
};

const setTasks = (state, tasks) => {
  state.tasks = tasks;
};

const setWidgets = (state, widgets) => {
  state.widgets = widgets;
};

export default {
  setProcesses,
  setProcessesUpdated,
  setDates,
  setDatesUpdated,
  setTasks,
  setTasksUpdated,
  setWidgets
};
