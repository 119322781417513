import workflowsService from '@/services/WorkflowsService';

const saveWorkflow = async (context, workflowData) => {
  return await workflowsService.saveWorkflow(workflowData);
};

const deleteWorkflow = async (context, workflowId) => {
  return await workflowsService.deleteWorkflow(workflowId);
};

const mapStatus = ({ state }, workflowData) => {
  const map = state.statusMapping.find(
    (x) => x.processType == workflowData.processType
  );
  const statusId = parseInt(workflowData.statusId);
  return map?.mapping[statusId] ?? [statusId];
};

export default {
  saveWorkflow,
  deleteWorkflow,
  mapStatus
};
