import notificationsService from '@/services/NotificationService';

const listTemplates = async () => {
  return await notificationsService.listTemplates();
};

const getTemplate = async (context, templateId) => {
  return await notificationsService.getTemplate(templateId);
};

const saveTemplate = async (context, template) => {
  return notificationsService.saveTemplate(template);
};

export default {
  listTemplates,
  getTemplate,
  saveTemplate
};
