import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr';
export default {
  install(Vue) {
    const connection = new HubConnectionBuilder()
      .withUrl(process.env.VUE_APP_HP_DPT_API + '/forms-hub')
      .configureLogging(LogLevel.Information)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch((err) => {
        console.error('Failed to connect with hub', err);
        return new Promise((resolve, reject) =>
          setTimeout(() => start().then(resolve).catch(reject), 5000)
        );
      });
      return startedPromise;
    }
    connection.onclose(() => start());

    start();

    // use new Vue instance as an event bus
    const formsHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$formsHub = formsHub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('FormSaved', (formId, correlationId) => {
      formsHub.$emit('form-saved', { formId, correlationId });
    });

    connection.on('FormDeleted', (formId, correlationId) => {
      formsHub.$emit('form-deleted', { formId, correlationId });
    });

    connection.on('FormCopied', (formId, copyId, newTitle, correlationId) => {
      formsHub.$emit('form-copied', {
        formId,
        copyId,
        newTitle,
        correlationId
      });
    });

    formsHub.enterCreateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('JoinFormGroup', correlationId))
        .catch(console.error);
    };

    formsHub.leaveCreateForm = (correlationId) => {
      if (!startedPromise) return;

      return startedPromise
        .then(() => connection.invoke('LeaveFormGroup', correlationId))
        .catch(console.error);
    };
  }
};
