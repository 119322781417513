export const adminRoutes = {
  routes: [
    {
      path: '/:slug/admin/notification-templates',
      name: 'NotificationTemplates',
      meta: {
        breadcrumbs: [
          { label: 'admin', icon: 'star' },
          {
            label: 'notificationTemplates.breadcrumb',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'notificationTemplates.breadcrumb'
      },
      component: () =>
        import(
          /* webpackChunkName: "notificaiton-template-overview" */
          '../views/admin/NotificationTemplates/Overview.vue'
        )
    },
    {
      path: '/:slug/admin/notification-templates/edit/:id',
      name: 'EditNotificationTemplate',
      meta: {
        breadcrumbs: [
          { label: 'admin', icon: 'star' },
          {
            label: 'notificationTemplates.breadcrumb',
            icon: 'account-multiple'
          },
          { label: 'overview' }
        ],
        title: 'notificationTemplates.breadcrumb'
      },
      component: () =>
        import(
          /* webpackChunkName: "notificaiton-template-overview" */
          '../views/admin/NotificationTemplates/Edit.vue'
        )
    }
  ]
};
