const clearFeedbackData = (state) => {
  state.currentFeedbackData = null;
};

const clearFeedbacks = (state) => {
  state.myFeedbacks = null;
};

export default {
  clearFeedbackData,
  clearFeedbacks
};
