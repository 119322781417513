import api from '@/helpers/api.js';

export default {
  async getNotifications(userId) {
    var data = await api.get(`/Notifications/${userId}`);
    return data.notifications;
  },

  async markAsRead(notificationId) {
    api.put(`/Notifications/MarkAsRead/${notificationId}`);
  },

  async listTemplates() {
    const { result } = await api.get(`/NotificationTemplates`);
    return result;
  },

  async getTemplate(templateId) {
    const { result } = await api.get(`/NotificationTemplates/${templateId}`);
    return result;
  },

  saveTemplate(template) {
    template.templateId = template.id;
    return api.post(`/NotificationTemplates`, template);
  }
};
