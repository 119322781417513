<template>
  <transition name="fade">
    <div id="loading-layer" v-if="isLoading">
      <div class="box">
        <div class="inner-box">
          <div class="muted mb-2">{{ $t('initializing') }}</div>

          <v-progress-linear
            v-if="value < 0"
            color="secondary"
            indeterminate
            rounded
            striped
            height="15"
          ></v-progress-linear>
          <v-progress-linear
            v-else
            :value="value"
            color="secondary"
            rounded
            striped
            height="15"
          ></v-progress-linear>

          <div class="status-label">
            {{ info }}
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: -1
    },
    visible: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      progress: this.value
    };
  },

  computed: {
    isLoading() {
      return this.visible || (this.progress >= 0 && this.progress <= 100);
    }
  },
  watch: {
    value(value) {
      if (value >= 100) {
        var self = this;
        setTimeout(function () {
          self.progress = value + 1;
        }, 200);
      } else {
        this.progress = value;
      }
    }
  },
  methods: {}
};
</script>

<style lang="scss">
#loading-layer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.85);

  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    .inner-box {
      padding: 2em 2em 2em 2em;
      width: 400px;
      background-color: #fff;

      .status-label {
        text-align: center;
        font-weight: bold;
        margin-top: 1em;
        font-size: 1.25em;
      }
    }
  }
}
</style>
