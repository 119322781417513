import Vue from 'vue';
import VueRouter from 'vue-router';

import { feedback } from '@/router/feedback-routes.js';
import { workflows } from './workflow-routes';
import { targets } from './target-routes';
import { adminRoutes } from './admin-routes.js';
import { reportingRoutes } from './reporting-routes.js';

Vue.use(VueRouter);

const routes = [
  {
    path: '/:slug/dashboard',
    name: 'Dashboard',
    meta: {
      breadcrumbs: [],
      title: 'Dashboard'
    },
    component: () =>
      import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue')
  },
  {
    path: '/:slug/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/callback',
    name: 'OidcCallback',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcCallback" */
        '../views/oidc/Callback.vue'
      )
  },

  {
    path: '/silent-renew',
    name: 'OidcSilentRenew',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Login'
    },
    component: () =>
      import(
        /* webpackChunkName: "oidcSilentRenew" */
        '../views/oidc/SilentRenew.vue'
      )
  },

  {
    path: '/:slug/admin/users',
    name: 'UserOverview',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'employees', icon: 'account-group' },
        { label: 'overview' }
      ],
      title: 'employeeOverview.title'
    },
    component: () =>
      import(
        /* webpackChunkName: "usersOverview" */
        '../views/admin/Users/Overview.vue'
      )
  },

  {
    path: '/:slug/admin/user/:shorthand',
    name: 'UserDetails',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'employees', icon: 'account-multiple' },
        { label: 'overview' },
        { label: 'employeeDetails.breadcrumb' }
      ],
      title: 'employeeDetails.breadcrumb'
    },
    component: () =>
      import(
        /* webpackChunkName: "usersDetails" */
        '../views/admin/Users/Details.vue'
      )
  },

  {
    path: '/:slug/admin/emails',
    name: 'EmailOverview',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'E-Mail', icon: 'mailbox' }
      ],
      title: 'emailsOverview'
    },
    component: () =>
      import(
        /* webpackChunkName: "emailsOverview" */
        '../views/admin/Emails/Overview.vue'
      )
  },

  {
    path: '/:slug/admin/error-log',
    name: 'ErrorLog',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'errorLog.menuItem', icon: 'alert-circle' }
      ],
      title: 'errorLog.menuItem'
    },
    component: () =>
      import(
        /* webpackChunkName: "emailsOverview" */
        '../views/admin/ErrorLog/Overview.vue'
      )
  },

  {
    path: '/:slug/admin/email/:id',
    name: 'EmailDetails',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'E-Mail', icon: 'mailbox' },
        { label: 'emailDetails.breadcrumb', icon: 'email' }
      ],
      title: 'emailDetails.breadcrumb'
    },
    component: () =>
      import(
        /* webpackChunkName: "emailsDetails" */
        '../views/admin/Emails/Details.vue'
      )
  },

  {
    path: '/:slug/admin/forms',
    name: 'FormsOverview',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'forms.forms', icon: 'text-box-multiple' }
      ],
      title: 'forms.preview'
    },
    component: () =>
      import(
        /* webpackChunkName: "formsOverview" */
        '../views/admin/Forms/Overview.vue'
      )
  },

  {
    path: '/:slug/admin/form/create',
    name: 'FormsCreate',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'forms.forms', icon: 'text-box-multiple', to: '/admin/forms' },
        { label: 'forms.new', icon: 'text-box-plus' }
      ],
      title: 'forms.add'
    },
    component: () =>
      import(
        /* webpackChunkName: "formsCreate" */
        '../views/admin/Forms/New.vue'
      )
  },

  {
    path: '/:slug/admin/form/edit/:id',
    name: 'FormsEdit',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'forms.forms', icon: 'text-box-multiple', to: '/admin/forms' },
        { label: 'forms.edit', icon: 'text-edit' }
      ],
      title: 'forms.edit'
    },
    component: () =>
      import(
        /* webpackChunkName: "formsEdit" */
        '../views/admin/Forms/Edit.vue'
      )
  },

  {
    path: '/:slug/admin/form/preview/:id',
    name: 'FormPreview',
    meta: {
      breadcrumbs: [
        { label: 'admin', icon: 'star' },
        { label: 'forms.forms', icon: 'text-box-multiple', to: '/admin/forms' },
        { label: 'forms.preview', icon: 'text-edit' }
      ],
      title: 'forms.preview'
    },
    component: () =>
      import(
        /* webpackChunkName: "formsPreview" */
        '../views/admin/Forms/Preview.vue'
      )
  },

  {
    path: '/:slug/promotions',
    name: 'Promotions',
    meta: {
      breadcrumbs: [],
      title: ''
    },
    component: () =>
      import(
        /* webpackChunkName: "promotions" */
        '../views/Promotions/overview.vue'
      )
  },

  {
    path: '/error-403',
    name: 'Error403',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 403'
    },
    component: () =>
      import(/* webpackChunkName: "error401" */ '../Error403.vue')
  },

  {
    path: '*',
    name: 'Error404',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 404'
    },
    component: () =>
      import(/* webpackChunkName: "error404" */ '../Error404.vue')
  },

  {
    path: '/error-500',
    name: 'Error500',
    meta: {
      breadcrumbs: [{ label: 'admin', icon: 'star' }],
      title: 'Error 500'
    },
    component: () =>
      import(/* webpackChunkName: "error500" */ '../Error500.vue')
  },

  ...feedback.routes,
  ...workflows.routes,
  ...targets.routes,
  ...adminRoutes.routes,
  ...reportingRoutes.routes
];

const router = new VueRouter({
  routes
});

export default router;
