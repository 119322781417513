import formsService from '@/services/FormsService';

const deleteForm = async (context, formId) => {
  await formsService.deleteForm(formId);
};

const listForms = async ({ commit, state }) => {
  if (
    state.listItems !== null &&
    state.listItems !== undefined &&
    state.listItems.length > 0
  ) {
    return state.listItems;
  }

  const forms = await formsService.listForms();
  commit('setFormsListItems', forms);

  return forms;
};

const loadCustomSettings = async ({ state, commit }, companySlug) => {
  if (state.customSettings === null) {
    const customSettings = await formsService.loadCustomSettings(companySlug);
    commit('setCustomSettings', customSettings);
  }

  return state.customSettings;
};

const loadForm = async (context, formId) => {
  return await formsService.loadForm(formId);
};

const saveForm = async (context, formData) => {
  await formsService.saveForm(formData.data, formData.isDraft);
};

const copyForm = async (context, formData) => {
  await formsService.copyForm(formData.id, formData.newTitle);
};

export default {
  deleteForm,
  listForms,
  loadForm,
  loadCustomSettings,
  saveForm,
  copyForm
};
