import { UserManager, WebStorageStateStore } from 'oidc-client';
import store from '@/store/index.js';
import Cookies from 'js-cookie';

const settings = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  authority: process.env.VUE_APP_STS_DOMAIN,
  scope: process.env.VUE_APP_SCOPE,
  client_id: process.env.VUE_APP_CLIENT_ID,
  redirect_uri: process.env.VUE_APP_REDIRECT_URI,
  automaticSilentRenew: true,
  validateSubOnSilentRenew: true,
  silent_redirect_uri: process.env.VUE_APP_SILENT_REDIRECT_URI,
  silentRequestTimeout: 10000,
  post_logout_redirect_uri: process.env.VUE_APP_POST_LOGOUT_REDIRECT_URI,
  response_type: 'code',
  popupWindowFeatures:
    'location=no,toolbar=no,width=450,height=650,left=250,top=100',
  filterProtocolClaims: false,
  loadUserInfo: true
};

export function useUserStore() {
  return new AuthService();
}

export default class AuthService {
  userManager;

  constructor() {
    let manager = store.state.userManager;
    if (!manager) {
      manager = new UserManager(settings);
      store.state.userManager = manager;
    }

    this.userManager = manager;
  }

  async getUser() {
    const user = await this.userManager.getUser();
    return user;
  }

  async login() {
    Cookies.set(
      'returnUrl',
      window.location.href.substring(window.location.href.indexOf('#') + 1)
    );

    return await this.userManager.signinRedirect();
  }

  async loginPopup() {
    var user = await this.userManager.signinPopup();

    if (user) {
      return user;
    }

    return null;
  }

  async logout() {
    return await this.userManager.signoutRedirect();
  }

  async logoutPopup() {
    return await this.userManager.signoutPopupCallback();
  }

  async getAccessToken() {
    var user = await this.userManager.getUser();

    if (user != null) {
      return user.access_token;
    }

    return null;
  }

  async signinRedirectCallback() {
    try {
      var user = await this.userManager.signinRedirectCallback();
      if (user) {
        user.returnUrl = Cookies.get('returnUrl');
        Cookies.remove('returnUrl');
        return user;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  async signinPopupCallback() {
    try {
      var user = await this.userManager.signinPopupCallback();

      if (user) {
        return user;
      }

      return null;
    } catch (error) {
      return null;
    }
  }

  async signinSilentCallback() {
    try {
      var user = await this.userManager.signinSilentCallback();

      if (user) {
        return user;
      }

      return null;
    } catch (error) {
      return null;
    }
  }
}
