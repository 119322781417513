const getFormName = (state) => {
  return (formId) => {
    if (!state.listItems) {
      return [];
    }

    const matches = state.listItems.filter((x) => x.id === formId);
    if (matches.length === 0) {
      return [];
    }

    return matches[0].name;
  };
};

const getForms = (state) => {
  return state.listItems ?? [];
};

export default {
  getFormName,
  getForms
};
